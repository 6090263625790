import { Container } from 'react-bootstrap';

import Login from './components/Login/Login'
import Logout from './components/Logout/Logout'
import Hooks from './components/Hooks/Hooks'

import useToken from './services/auth/auth';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const { token, setToken } = useToken();

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('jwt');
  if (code) {
    setToken(code)
    window.history.pushState(null, 'login', '/')
  }

  if (!token) {
    return <Login /> 
  }

  return (
    <div>
      <Logout setToken={setToken}/>
      <Container fluid>
        <Hooks />
      </Container>
    </div>
  );
}

export default App;
