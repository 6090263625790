import { FunctionComponent, MouseEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { Button, Row, Col } from 'react-bootstrap';

import useHooks from '../../services/getHooks/getHooks'
import { Modal } from 'react-bootstrap';

interface CardProps {
    title: string
    hookID: string
    clickHookCallback: any
}

const Card : FunctionComponent<CardProps> = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { removeHook } = useHooks();

    async function handleRemove(e: MouseEvent) {
        setShow(false)
        await removeHook(props.hookID)
        props.clickHookCallback()
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete webhook</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this webhook?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleRemove}>Delete</Button>
                </Modal.Footer>
            </Modal>
            <div className="discord-card" onClick={handleShow}>
                <Row>
                    <Col>
                        <span>{props.title}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FontAwesomeIcon icon={faDiscord} className="fa-5x"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span>Click to delete</span>
                    </Col>
                </Row>
            </div> 
        </div>
    )
}

export default Card