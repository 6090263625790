import { FunctionComponent } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

import './logout.css';

interface LogoutProps {
    setToken: any
}

const Navigation : FunctionComponent<LogoutProps> = (props) => {
    function clearToken() {
        props.setToken('')
    }    

    return (
        <Navbar expand='lg' bg='dark' variant='dark'>
            <Container fluid>
                <Navbar.Brand href="/">Stream Notifier</Navbar.Brand>
                <Nav className="mr-auto">
                    <Nav.Link onClick={clearToken}>Logout</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}

export default Navigation