import { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import useHooks from '../../services/getHooks/getHooks'
import Card from './Card'
import NewHook from './NewHook'

import './hooks.css'

const Hooks : FunctionComponent = () => {
    const [hooks, setHooks] = useState([])
    const { getHooks } = useHooks();

    async function reloadHooks() {
        getHooks().then(data => setHooks(data))
    }

    useEffect(() => {
        async function fetchData() {
            reloadHooks()
        }
        fetchData()
    }, [setHooks])

    const onNewHookCallback = () => {
        reloadHooks()
    }
    
    if (hooks.length === 0) {
        return(
            <NewHook clickHookCallback={onNewHookCallback} activity="stream.started" title="Stream started" />
        )
    } else {
        return (
            <Row>
                {hooks.map((hook, _) => (
                    <Col key={hook} className="text-center">
                        <Card hookID={hook} clickHookCallback={reloadHooks} title="Stream Started"/>
                    </Col>
                ))}
            </Row>
        )
    }
    
}

export default Hooks