import { FunctionComponent, useState } from 'react';
import { Container, Row, Col, Modal, InputGroup, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import useHooks from '../../services/getHooks/getHooks'
import { Alert } from 'react-bootstrap';

interface NewHookProps {
    activity: string
    title: string
    clickHookCallback: any
}

const NewHook : FunctionComponent<NewHookProps> = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const { newHook } = useHooks();
    const [formData, setFormData] = useState({hook: ""})

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const [alert, setAlert] = useState("")
    const [showAlert, setShowAlert] = useState(false)

    async function onSubmit() {
        if (formData.hook.length === 0) {
            setAlert("You must supply a webhook value")
            setShowAlert(true)
            return
        }
        setShowAlert(false)
        const response = await newHook(formData.hook)
        if (response.length === 0) {
            props.clickHookCallback()
            return
        }
        setAlert(response)
        setShowAlert(true)
    }

    return (
        <Container fluid>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a new webhook</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="danger" show={showAlert}>{alert}</Alert>
                    <InputGroup>
                        <Form.Control
                            placeholder="Webhook URL"
                            aria-label="Webhook URL"
                            aria-describedby="submit-button"
                            name="hook"
                            onChange={handleChange} />
                    </InputGroup>
                    <Form.Text muted>
                        Need help getting your Webhook URL? Click <a href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks" target="_blank" rel="noreferrer">here</a>.
                    </Form.Text>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={onSubmit}>Save Changes</Button>
                </Modal.Footer>
            </Modal>
            <Row>
                <Col className="text-center">
                    <div className="discord-card" onClick={handleShow}>
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faPlusCircle} className="fa-5x"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>Click to add a new webhook</span>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default NewHook