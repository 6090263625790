import useToken from "../auth/auth";

export default function useHooks() {
    const { token } = useToken();   

    async function getHooks() {
        const response = await window.fetch('/api/webhooks', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
        const result = await response.json()
        return result
    }

    async function newHook(url: string) : Promise<string> {
        const response = await window.fetch('/api/webhook', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({hook: url})
        })
        if (!response.ok) {
            return response.statusText
        }
        return ""
    }

    async function removeHook(id: string) {
        const response = await window.fetch('/api/webhook/' + id, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
        console.log(response.ok)
    }

    return {
        getHooks,
        newHook,
        removeHook
    }
}