import { FunctionComponent } from 'react';
import { Button, Navbar, Container } from 'react-bootstrap';

import './login.css';

const Login : FunctionComponent = () => {
    return(
        <Navbar expand='lg' bg='dark' variant='dark'>
            <Container fluid>
                <Navbar.Brand href="/">Stream Notifier</Navbar.Brand>
                <Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
                    <Button variant='primary' href='/api/auth/twitch'>Login with Twitch</Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Login