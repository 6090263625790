import { useState } from 'react'

export default function useToken() {
    const saveToken = (userToken: string) => {
        if (!userToken || userToken.length === 0) {
            sessionStorage.removeItem('token')
        } else {
            sessionStorage.setItem('token', JSON.stringify(userToken));
        }
        setToken(userToken)
    }

    const getToken = () : string => {
        const tokenString = sessionStorage.getItem('token');
        if (tokenString) {
            return JSON.parse(tokenString)
        }
        return ''
    }

    const [token, setToken] = useState(getToken());

    return {
        setToken: saveToken,
        token
    }
}